import { ICustomPages } from './types';

function removeTrailingSlash(str: string) {
  if (str && str.endsWith('/')) {
    return str.slice(0, -1);
  }
  return str;
}

const BASE_URL = removeTrailingSlash(process.env.GATSBY_PFF_BASE_URL || '');
const environment = process.env.GATSBY_PFF_ENV || 'develop';
const isProduction = BASE_URL === 'https://www.purina.ca';

const CustomPages: ICustomPages = {
  '/do-not-sell-or-share-my-personal-information': {
    component: 'src/templates/custom-pages/do-not-sell.tsx',
  },
  '/dogs/dog-breeds/collections': {
    component: 'src/templates/custom-pages/breed-collections-listing.tsx',
    context: {
      species: 1117, // Species ID for Dogs.
      pageType: 'breed_listing',
      language: 'en',
    },
  },
  '/fr/dogs/dog-breeds/collections': {
    component: 'src/templates/custom-pages/breed-collections-listing.tsx',
    context: {
      species: 1117, // Species ID for Dogs.
      pageType: 'breed_listing',
      language: 'fr',
    },
  },
  '/cats/cat-breeds/collections': {
    component: 'src/templates/custom-pages/breed-collections-listing.tsx',
    context: {
      species: 1120, // Species ID for Cats.
      pageType: 'breed_listing',
      language: 'en',
    },
  },
  '/fr/cats/cat-breeds/collections': {
    component: 'src/templates/custom-pages/breed-collections-listing.tsx',
    context: {
      species: 1120, // Species ID for Cats.
      pageType: 'breed_listing',
      language: 'fr',
    },
  },
  '/pet-food-feeding-guide-calculator-direct': {
    component: 'src/templates/custom-pages/feeding-guide-direct.tsx',
    context: {
      baseUrl: BASE_URL,
      isProduction,
      env: environment,
      pageType: 'feeding_guide',
      languageCode: 'en',
    },
  },
  '/pet-food-finder-direct': {
    component: 'src/templates/custom-pages/pet-food-finder.tsx',
    context: {
      client_id: 'f5924d3a-3208-45c1-a804-c1ed1ac5a99d',
      baseUrl: BASE_URL,
      isProduction,
      theme_key: 'pff',
      consumer_key: 'canada',
      env: environment,
      pricespider_widget_id: '60f0869109ece10018627c2e',
      pageType: 'pet_food_finder',
      languageCode: 'en',
    },
  },
  '/fr/pet-food-finder-direct': {
    component: 'src/templates/custom-pages/pet-food-finder.tsx',
    context: {
      client_id: 'f5924d3a-3208-45c1-a804-c1ed1ac5a99d',
      baseUrl: BASE_URL,
      isProduction,
      theme_key: 'pff',
      consumer_key: 'canada',
      env: environment,
      pricespider_widget_id: '60f0869109ece10018627c2e',
      pageType: 'pet_food_finder',
      languageCode: 'fr',
    },
  },
  '/pro-plan/myplan-direct': {
    component: 'src/templates/custom-pages/pet-food-finder.tsx',
    context: {
      client_id: 'f5924d3a-3208-45c1-a804-c1ed1ac5a99d',
      baseUrl: BASE_URL,
      isProduction,
      theme_key: 'my_plan',
      consumer_key: 'default',
      pricespider_widget_id: '60f0869109ece10018627c2e',
      pageType: 'pet_food_finder',
    },
  },
  '/pro-plan/myplan-psp-direct': {
    component: 'src/templates/custom-pages/pet-food-finder.tsx',
    context: {
      client_id: 'f5924d3a-3208-45c1-a804-c1ed1ac5a99d',
      baseUrl: BASE_URL,
      isProduction,
      theme_key: 'pet_supplies_plus',
      consumer_key: 'default',
      pricespider_widget_id: '63fe5067d5cdda0066d753ba',
      pageType: 'pet_food_finder',
    },
  },
  '/pro-plan/myplan-vet-direct': {
    component: 'src/templates/custom-pages/pet-food-finder.tsx',
    context: {
      client_id: 'f5924d3a-3208-45c1-a804-c1ed1ac5a99d',
      baseUrl: BASE_URL,
      isProduction,
      theme_key: 'vet_direct',
      consumer_key: 'default',
      pricespider_widget_id: '60f0869109ece10018627c2e',
      pageType: 'pet_food_finder',
    },
  },
  '/purina-one/pet-food-finder-direct': {
    component: 'src/templates/custom-pages/pet-food-finder.tsx',
    context: {
      client_id: 'f5924d3a-3208-45c1-a804-c1ed1ac5a99d',
      baseUrl: BASE_URL,
      isProduction,
      theme_key: 'purina_one',
      consumer_key: 'default',
      pricespider_widget_id: '60f0869109ece10018627c2e',
      pageType: 'pet_food_finder',
    },
  },
  '/international-sites': {
    component: 'src/templates/custom-pages/international-sites.tsx',
  },
};

export default CustomPages;
